.row-border{
    /*border: 10px solid blue;*/
    padding-top: 15%;
}


.red-box {
    border-bottom: 4px solid brown;
    width: 50%;

}

.paragraph-box{
    width: 80%;
    padding-top: 5%;
    /*padding: 1% 1% 1% 1%;*/
    text-align: left !important;
}

.copy {
    padding-top: 0.25%;
    padding-bottom: 0.25%;
}

.offset{
    padding-top: 20%;
}

.my-pic {
 
    object-fit: contain; 
    box-shadow: 20px 20px rgba(0,0,0,.1);
    transition: all .4s ease;
    width: 100%;
    max-width: 300px;
    height: auto;
    display: block;
    margin: 0 auto;

  }
  
  .my-pic:hover {
    box-shadow: 10px 10px rgba(0,0,0,.20);
}


.stack-divs{
    padding-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
    text-align: left !important;
}

.term-main{
    padding-bottom: 10%;
}

.education-div {
    max-width: 50%;
    padding-top: 8%;
}


.no-bllt {
    list-style-type: none;
}

.pad-btm{
    padding-bottom: 4%;
}

.ec1{
    padding-left: 10%;
    padding-right: 10%;
}

.ec2{
    padding-top: 10%;
    padding-bottom: 10%;
}

.pad-card-bd {
    margin-top: 20%;
}

.ec-underline {
    border-bottom: 4px solid brown;
    width: 800px;

}