
.row-border-contact{
    /*border: 10px solid blue;*/
    padding-top: 0%;
}

.offset-mail {
    padding-top: 5%;
}
.red-box {
    border-bottom: 4px solid brown;
    width: 450px;

}

.paragraph-box{
    width: 80%;
    padding-top: 5%;
    /*padding: 1% 1% 1% 1%;*/
    text-align: left !important;
}

.copy {
    padding-top: 1%;
}

.offset{
    padding-top: 20%;
}

.my-pic {
 
    object-fit: contain; 
    box-shadow: 20px 20px rgba(0,0,0,.1);
    transition: all .4s ease;
    width: 100%;
    max-width: 300px;
    height: auto;
    display: block;
    margin: 0 auto;

  }
  
  .my-pic:hover {
    box-shadow: 10px 10px rgba(0,0,0,.20);
}


.stack-divs{
    padding-top: 10%;
    padding-left: 2%;
    padding-right: 2%;
    text-align: left !important;
}

.term-main{
    padding-bottom: 10%;
}

.contact-form{
    padding-left: 10%;
    padding-right: 10%;
    text-align: left !important;
}

.bg-my-red-btn{
    background-color: brown;
    border-color: rgb(165, 42, 42);

}
.bg-my-red-btn:hover :active :focus :visited {
  background-color: black;
  border-color: black;
}