.bg-my-red{
    background-color: brown;
}

.align-left-logo {
    margin-left: 20px;
}

.align-right-logo {
    margin-right: 10px;
}